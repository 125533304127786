import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import SectionTitle from './Components/SectionTitle'
 
export default function ContentSectionOne(){
return(
<Content>
  <Container>
    <Content.InnerWrapper>
      <Row className="align-items-center justify-content-center">
        <Col xs="12" className="col-lg-4 col-md-8 col-xs-11 text-center text-lg-start">
          {/* Section Title */}
          <SectionTitle 
                subTitle="Life as a Surgeon" 
                title="A Pioneer in the field of Endocrine Surgery"
                text="Prof. Maharajan Chandrasekaran is a pioneer in the field of Endocrine Surgery. In recognition of his achievements in the field of surgery, the Royal College of Physicians and Surgeons
                of Glasgow conferred upon him FRCS(Glasg) in 2010."
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />
               
        </Col>
        <Col xs="12" className="col-xxl-5 col-lg-4 col-md-6 col-xs-9">
          <Content.Image mt="30px" mb="30px" mbMD="0" mtMD="0">
            <Img src="../../../assets/image/home-agency/mc.png" alt="content" layout="fullWidth" placeholder=""/>
          </Content.Image>
        </Col>
        <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-9 order-3">
          <Content.WidgetsWrapper mb="-10px" mtMD="40px" mtLG="0" mrXS="70px" mrMD="30px" mrLG="0">
            {/* Single Services */}
            <Content.Widget pb="10px">
              <Content.WidgetTitle>41 Years of Surgical Experience</Content.WidgetTitle>
              <Content.WidgetText>Maharajan Chandrasekaran graduated from Madras Medical College in the year 1976 and obtained his Masters in General Surgery from the same college in 1981.</Content.WidgetText>
            </Content.Widget>
            {/*/ .Single Services */}
            {/* Single Services */}
            <Content.Widget pb="10px">
              <Content.WidgetTitle>7,200+ thyroidectomies</Content.WidgetTitle>
              <Content.WidgetText> Dr. MC has performed over 7,200 thyroidectomies and has devised a unique surgical technique to excise the Lingual thyroid.</Content.WidgetText>
            </Content.Widget>
            {/*/ .Single Services */}
            {/* Single Services */}
            <Content.Widget pb="10px">
              <Content.WidgetTitle>Started South India's 1st M.Ch Course for Endocrine Surgery in 2007</Content.WidgetTitle>
              <Content.WidgetText>This is the only (M.Ch) Surgical Superspeciality course started in The Tamilnadu Dr.M.G.R. Medical university since its inception in the year 1987. </Content.WidgetText>
            </Content.Widget>
            {/*/ .Single Services */}
          </Content.WidgetsWrapper>
        </Col>
      </Row>
    </Content.InnerWrapper>
  </Container>
</Content>

)
}