import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import CounterBlock from './Components/CounterBlock'
export default function FeatureSection(){
return(
<Feature backgroundColor="#f3f4f6">
  <Container>
    {/* Section Title */}
    <Row>
      <Col className="col-xl-8 col-lg-10">
        <Feature.Box>
        <SectionTitle 
                subTitle="Life as an Academician" 
                title="An Endocrine surgeon with  <br class='d-none d-xs-block'>
                a flair for academics "
                titleProps={{mb:"33px"}}
                subTitleProps={{mb:"20px"}}
                />
        </Feature.Box>
      </Col>
    </Row>
    <Row>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
          Prof. Maharajan Chandrasekaran, a pioneer in the field of Endocrine Surgery,
has made significant contributions to the field over the past 37 years. Maharajan
Chandrasekaran graduated from Madras Medical College in the year 1976 and
later obtained his Masters in General Surgery from the same college in 1981. After
joining the Department of Endocrine Surgery in the year 1987, he has constantly
endeavoured to take Endocrine Surgery to greater heights. During his tenure as the
Head of the Department he started a super speciality course - M.Ch in Endocrine
Surgery in the year 2007. He has been an undergraduate and post-graduate teacher for 30 long years.

          </Feature.Text>
        </Feature.Box>
      </Col>
      <Col xs="12" className="col-lg-6 col-md-12 mb-5 mb-lg-7">
        <Feature.Box>
          <Feature.Text mrLG="30px">
          Prof.Chandrasekaran was the founder secretary and past President of the “Indian Association of
Endocrine Surgeons.” He also donned the role of Editor in Chief of the “Indian Journal of
EndocrineSurgery” for three years. He designed and launched Med-ej, the official e-journal of the
Tamilnadu Dr.M.G.R.Medical University - the first of its kind in India.
He has performed over 7,200 thyroidectomies and has devised a unique surgical technique to excise
the Lingual thyroid. You can read more about this technique in the Article titled “ Excision of Lingual
thyroid - How I do it.”
In recognition of his achievements in the field of surgery, Royal College of Physicians and Surgeons
of Glasgow conferred upon him FRCS and the Tamilnadu Dr.M.G.R.Medical University conferred
upon him the Scroll of Honour twice.
          </Feature.Text>
        </Feature.Box>
      </Col>
    </Row>
    
    {/*/ .Feature Content */}
  </Container>
</Feature>

)
}