import React from "react";
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";
import AboutSection from '~sections/about/about'
import FeatureSection from '~sections/about/Feature'
import ContentSection from '~sections/about/Content'
import CtaSection from '~sections/agency/Cta'
import HeaderButton from '~sections/agency/Header'
import PromoSection from '~sections/about/Promo'
import FooterFive from '~sections/agency/FooterFive'
import PortfolioDetailsSection from '~sections/portfolio-details/PortfolioDetailsTwo/PortfolioDetails'

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnText="Appointments"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function About() {
  return (
    <PageWrapper headerConfig={header}>
        <BreadCrumbSection title="About Dr.M Chandrasekaran" text=""/>
        <ContentSection/>
        <FeatureSection/>
        <PortfolioDetailsSection/>
        <AboutSection/>
        <CtaSection/>
        <FooterFive/>
    </PageWrapper>
  )
}
